<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#D5DCED;"
      d="M503.172,432.552H8.828c-4.875,0-8.828-3.953-8.828-8.828V185.379h512v238.345
	C512,428.599,508.047,432.552,503.172,432.552z"
    />
    <path
      style="fill:#F1F4FB;"
      d="M503.172,326.621H8.828c-4.875,0-8.828-3.953-8.828-8.828V88.276c0-4.875,3.953-8.828,8.828-8.828
	h494.345c4.875,0,8.828,3.953,8.828,8.828v229.517C512,322.668,508.047,326.621,503.172,326.621z"
    />
    <path
      style="fill:#B4E66E;"
      d="M476.69,141.241c-14.603,0-26.483-11.88-26.483-26.483c0-4.875-3.948-8.828-8.828-8.828H70.621
	c-4.879,0-8.828,3.953-8.828,8.828c0,14.603-11.88,26.483-26.483,26.483c-4.879,0-8.828,3.953-8.828,8.828V256
	c0,4.875,3.948,8.828,8.828,8.828c14.603,0,26.483,11.88,26.483,26.483c0,4.875,3.948,8.828,8.828,8.828h370.759
	c4.879,0,8.828-3.953,8.828-8.828c0-14.603,11.88-26.483,26.483-26.483c4.879,0,8.828-3.953,8.828-8.828V150.069
	C485.517,145.194,481.569,141.241,476.69,141.241z M44.138,248.061v-90.051c17.267-3.518,30.905-17.155,34.422-34.422h84.972
	c3.697,0,5.748,4.265,3.414,7.133c-16.058,19.733-25.705,44.888-25.705,72.314s9.646,52.582,25.705,72.316
	c2.334,2.867,0.282,7.133-3.414,7.133H78.56C75.043,265.216,61.405,251.577,44.138,248.061z M467.862,248.061
	c-17.267,3.518-30.905,17.155-34.422,34.422h-84.972c-3.697,0-5.748-4.265-3.414-7.133c16.058-19.734,25.705-44.889,25.705-72.316
	s-9.646-52.582-25.705-72.316c-2.334-2.868-0.283-7.133,3.414-7.133h84.972c3.518,17.267,17.155,30.905,34.422,34.422V248.061z"
    />
    <circle style="fill:#A0D755;" cx="256" cy="203.034" r="97.103" />
    <path
      style="fill:#F1F4FB;"
      d="M264.828,196.255V168.49c11.081,1.836,17.655,6.748,17.655,9.717c0,4.875,3.948,8.828,8.828,8.828
	c4.879,0,8.828-3.953,8.828-8.828c0-14.102-14.671-25.137-35.31-27.605v-0.533c0-4.875-3.948-8.828-8.828-8.828
	c-4.879,0-8.828,3.953-8.828,8.828v0.533c-20.639,2.467-35.31,13.502-35.31,27.605c0,19.646,19.304,27.132,35.31,31.607v27.765
	c-11.081-1.836-17.655-6.748-17.655-9.717c0-4.875-3.948-8.828-8.828-8.828s-8.828,3.953-8.828,8.828
	c0,14.102,14.671,25.137,35.31,27.605V256c0,4.875,3.948,8.828,8.828,8.828c4.879,0,8.828-3.953,8.828-8.828v-0.533
	c20.639-2.467,35.31-13.502,35.31-27.605C300.138,208.216,280.834,200.73,264.828,196.255z M229.517,178.207
	c0-2.969,6.574-7.881,17.655-9.717v22.798C234.446,187.172,229.517,183.402,229.517,178.207z M264.828,237.579v-22.798
	c12.726,4.116,17.655,7.887,17.655,13.081C282.483,230.831,275.908,235.743,264.828,237.579z"
    />
    <g>
      <circle style="fill:#B4E66E;" cx="35.31" cy="114.759" r="8.828" />
      <circle style="fill:#B4E66E;" cx="476.69" cy="114.759" r="8.828" />
      <circle style="fill:#B4E66E;" cx="35.31" cy="291.31" r="8.828" />
      <circle style="fill:#B4E66E;" cx="476.69" cy="291.31" r="8.828" />
    </g>
    <g>
      <rect y="344.276" style="fill:#C7CFE2;" width="512" height="17.655" />
      <rect y="379.586" style="fill:#C7CFE2;" width="512" height="17.655" />
      <path
        style="fill:#C7CFE2;"
        d="M503.172,432.552H8.828c-4.875,0-8.828-3.953-8.828-8.828v-8.828h512v8.828
		C512,428.599,508.047,432.552,503.172,432.552z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
